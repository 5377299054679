import React, { Component } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Formik } from 'formik'
import * as Yup from 'yup'

function encode(data) {
	return Object.keys(data)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&')
}

function validationIcons(valid) {
	if (valid === true) {
		return (
			<span className="icon is-small is-right">
				<i className="fas fa-exclamation-triangle" />
			</span>
		)
	}
	if (valid === false) {
		return (
			<span className="icon is-small is-right">
				<i className="fas fa-check" />
			</span>
		)
	}
	return ''
}

const FormSchema = Yup.object().shape({
	firstName: Yup.string()
		.min(2, 'Too short.')
		.max(50, 'Too long.')
		.required('Please provide a name.'),
	lastName: Yup.string()
		.min(2, 'Too short.')
		.max(50, 'Too long.')
		.required('Please provide a last name.'),
	email: Yup.string()
		.email('Invalid email address.')
		.required('Please provide an email address.'),
	mobile: Yup.string().matches(
		/^(?:\+?61|0)4 ?(?:(?:[01] ?[0-9]|2 ?[0-57-9]|3 ?[1-9]|4 ?[7-9]|5 ?[018]) ?[0-9]|3 ?0 ?[0-5])(?: ?[0-9]){5}$/,
		'Invalid number.'
	),
	postcode: Yup.string()
		.matches(/^[0-9]{4}/, 'Invalid Australian postcode.')
		.required('Please provide an postcode.'),
	pool: Yup.string().required('Pool state is required.'),
})

class ContactForm extends Component {
	componentDidMount() {
		const script = document.createElement('script')
		script.src = 'https://www.google.com/recaptcha/api.js'
		script.async = true
		script.defer = true
		document.body.appendChild(script)
	}
	render() {
		const props = this.props
		return (
			<div className="contactForm">
				<div className="container">
					<Formik
						initialValues={{
							'important-name-field': '',
							firstName: '',
							lastName: '',
							email: '',
							mobile: '',
							postcode: '',
							pool: '',
							message: '',
						}}
						validationSchema={FormSchema}
						onSubmit={(
							values,
							{ setSubmitting, setErrors, setStatus, setTouched, setValues }
						) => {
							setSubmitting(true)
							const body = {
								orgid: '00D58000000JkgU',
								retURL:
									'https://www.mineralswim.com/book-a-consultation/?form=contact',
								subject: 'Mineral Swim - Contact',
								'00N4H00000E2wq9':
									'https://www.mineralswim.com/book-a-consultation/',
								recordType: '0124H0000006NyI',
								origin: 'Contact Us WebSite',
								name: `${values.firstName} ${values.lastName}`,
								email: values.email,
								phone: values.mobile,
								'00N4H00000E2wqC': values.postcode,
								description: values.message,
								'00N4H00000E2wq7': 'Australia',
							}
							if (values['important-name-field']) {
								return setSubmitting(false)
							}
							fetch(
								'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
								{
									method: 'POST',
									headers: {
										'Content-Type': 'application/x-www-form-urlencoded',
									},
									body: encode(body),
								}
							)
								.then(res => '')
								.catch(err => '')
							setStatus('success')
							setSubmitting(false)
							setValues({
								firstName: '',
								lastName: '',
								email: '',
								mobile: '',
								postcode: '',
								pool: '',
								message: '',
							})
							setErrors('')
							setTouched('')
							return navigate('?form=contact')
						}}
						render={({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							setStatus,
							status,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit} className="form">
								<p className="is-hidden">
									<label htmlFor="important-name">
										IMPORTANT! Fill this out if you’re inhuman:{' '}
										<input id="important-name" name="important-name-field" />
									</label>
								</p>
								<div className="content">
									<h3 className="has-text-centered">{props.title}</h3>
								</div>
								<div className="field is-horizontal">
									<div className="field-label">
										<label className="label" htmlFor="first-name">
											First Names
										</label>
									</div>
									<div className="field-body">
										<div
											className="field is-expanded"
											aria-describedby="first-name"
										>
											<div className="control has-icons-left has-icons-right">
												<input
													id="first-name"
													name="firstName"
													type="text"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.firstName}
													placeholder="First Name"
													className={classNames(
														touched.firstName &&
															errors.firstName &&
															`is-danger`,
														values.firstName &&
															!errors.firstName &&
															`is-success`,
														`input`
													)}
												/>
												<span className="icon is-small is-left">
													<i className="fas fa-user" />
												</span>
												{touched.firstName &&
													errors.firstName &&
													validationIcons(true)}
												{values.firstName &&
													!errors.firstName &&
													validationIcons(false)}
											</div>
											{touched.firstName && errors.firstName && (
												<p
													className="help is-danger"
													id="first-name-helper-text"
												>
													{errors.firstName}
												</p>
											)}
										</div>
									</div>
								</div>
								<div className="field is-horizontal">
									<div className="field-label">
										<label className="label" htmlFor="last-name">
											Last Names
										</label>
									</div>
									<div className="field-body">
										<div
											className="field is-expanded"
											aria-describedby="last-name"
										>
											<div className="control has-icons-left has-icons-right">
												<input
													id="last-name"
													name="lastName"
													type="text"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.lastName}
													placeholder="Last Name"
													className={classNames(
														touched.lastName && errors.lastName && `is-danger`,
														values.lastName && !errors.lastName && `is-success`,
														`input`
													)}
												/>
												<span className="icon is-small is-left">
													<i className="fas fa-user" />
												</span>
												{touched.lastName &&
													errors.lastName &&
													validationIcons(true)}
												{values.lastName &&
													!errors.lastName &&
													validationIcons(false)}
											</div>
											{touched.lastName && errors.lastName && (
												<p
													className="help is-danger"
													id="last-name-helper-text"
												>
													{errors.lastName}
												</p>
											)}
										</div>
									</div>
								</div>
								<div className="field is-horizontal">
									<div className="field-label">
										<label className="label" htmlFor="email">
											Email
										</label>
									</div>
									<div className="field-body">
										<div className="field is-expanded" aria-describedby="email">
											<div className="control has-icons-left has-icons-right">
												<input
													id="email"
													name="email"
													type="email"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.email}
													placeholder="you@example.com"
													className={classNames(
														touched.email && errors.email && `is-danger`,
														values.email && !errors.email && `is-success`,
														`input`
													)}
												/>
												<span className="icon is-small is-left">
													<i className="fas fa-envelope" />
												</span>
												{touched.email && errors.email && validationIcons(true)}
												{values.email &&
													!errors.email &&
													validationIcons(false)}
											</div>
											{touched.email && errors.email && (
												<p className="help is-danger" id="email-helper-text">
													{errors.email}
												</p>
											)}
										</div>
									</div>
								</div>
								{props && props.mobile !== 'nil' && (
									<div className="field is-horizontal">
										<div className="field-label">
											<label className="label" htmlFor="mobile">
												Mobile
											</label>
										</div>
										<div className="field-body">
											<div
												className="field is-expanded"
												aria-describedby="mobile"
											>
												<div className="control has-icons-left has-icons-right">
													<input
														id="mobile"
														name="mobile"
														type="tel"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.mobile}
														placeholder="Mobile"
														className={classNames(
															touched.mobile && errors.mobile && `is-danger`,
															values.mobile && !errors.mobile && `is-success`,
															`input`
														)}
													/>
													<span className="icon is-small is-left">
														<i className="fas fa-phone" />
													</span>
													{touched.mobile &&
														errors.mobile &&
														validationIcons(true)}
													{values.mobile &&
														!errors.mobile &&
														validationIcons(false)}
												</div>
												{touched.mobile && errors.mobile && (
													<p className="help is-danger" id="mobile-helper-text">
														{errors.mobile}
													</p>
												)}
											</div>
										</div>
									</div>
								)}
								<div className="field is-horizontal">
									<div className="field-label">
										<label className="label" htmlFor="postcode">
											Postcode
										</label>
									</div>
									<div className="field-body">
										<div
											className="field is-expanded"
											aria-describedby="postcode"
										>
											<div className="control has-icons-left has-icons-right">
												<input
													id="postcode"
													name="postcode"
													type="tel"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.postcode}
													placeholder="Postcode"
													className={classNames(
														touched.postcode && errors.postcode && `is-danger`,
														values.postcode && !errors.postcode && `is-success`,
														`input`
													)}
												/>
												<span className="icon is-small is-left">
													<i className="fas fa-home" />
												</span>
												{touched.postcode &&
													errors.postcode &&
													validationIcons(true)}
												{values.postcode &&
													!errors.postcode &&
													validationIcons(false)}
											</div>
											{touched.postcode && errors.postcode && (
												<p className="help is-danger" id="postcode-helper-text">
													{errors.postcode}
												</p>
											)}
										</div>
									</div>
								</div>
								<div className="field is-horizontal">
									<div className="field-label">
										<label
											className="label"
											htmlFor="pool-is-exisitng"
											id="pool"
										>
											Pool
										</label>
									</div>
									<div className="field-body">
										<div
											id="pool-is-exisitng"
											className="field is-expanded"
											aria-describedby="pool"
										>
											<div className="control">
												<label className="radio">
													<input
														id="pool-new"
														name="pool"
														type="radio"
														onChange={handleChange}
														onBlur={handleBlur}
														value={'pool-new'}
														checked={values.pool === 'pool-new'}
													/>
													&nbsp;New Pool
												</label>
												<label className="radio">
													<input
														id="pool-existing"
														name="pool"
														type="radio"
														onChange={handleChange}
														onBlur={handleBlur}
														value={'pool-existing'}
														checked={values.pool === 'pool-existing'}
													/>
													&nbsp;Existing Pool
												</label>
											</div>
											{touched.pool && errors.pool && (
												<p className="help is-danger" id="pool-helper-text">
													{errors.pool}
												</p>
											)}
										</div>
									</div>
								</div>
								<div className="field is-horizontal">
									<div className="field-label">
										<label className="label" htmlFor="message">
											Message
										</label>
									</div>
									<div className="field-body">
										<div
											className="field is-expanded"
											aria-describedby="message"
										>
											<div className="control has-icons-right">
												<textarea
													id="message"
													name="message"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.message}
													placeholder="...type a message 😄"
													className={classNames(
														errors.message && `is-danger`,
														values.message && !errors.message && `is-success`,
														`textarea`
													)}
												/>
												{values.message &&
													errors.message &&
													validationIcons(true)}
												{values.message &&
													!errors.message &&
													validationIcons(false)}
											</div>
											{values.message && errors.message && (
												<p className="help is-danger" id="message-helper-text">
													{errors.message}
												</p>
											)}
										</div>
									</div>
								</div>
								<p className="has-text-centered">
									<button
										type="submit"
										disabled={isSubmitting}
										className={classNames(
											isSubmitting && `is-loading`,
											`button is-secondary is-medium`
										)}
									>
										Submit
									</button>
								</p>
								<div
									className={status === 'success' ? `is-active modal` : `modal`}
									id="form-success"
								>
									<div
										role="button"
										tabIndex="-20"
										className="modal-background"
										onClick={() => setStatus('')}
										onKeyPress={() => setStatus('')}
									/>
									<div className="modal-content">
										<div className="box">
											<div className="content">
												<h1 id="success-message-title">
													We have received your enquiry{' '}
													<span
														role="img"
														aria-labelledby="success-message-title"
													>
														😄
													</span>
												</h1>
												<p>
													Thanks for taking the time to book a consultation.
													<br />
													We will be in touch soon.
												</p>
											</div>
										</div>
									</div>
									<button
										className="modal-close is-large"
										aria-label="close"
										onClick={() => setStatus('')}
									/>
								</div>
								<div
									className={
										status !== 'success' && status ? `is-active modal` : `modal`
									}
									id="form-failure"
								>
									<div
										role="button"
										tabIndex="-21"
										className="modal-background"
										onClick={() => setStatus('')}
										onKeyPress={() => setStatus('')}
									/>
									<div className="modal-content">
										<div className="box">
											<div className="content">
												<h1 id="failure-message-title">
													Something went wrong{' '}
													<span
														role="img"
														aria-labelledby="failure-message-title"
													>
														😞
													</span>
												</h1>
												<p>
													Form is invalid, please check your details and try
													again.
												</p>
												<pre>{status}</pre>
											</div>
										</div>
									</div>
									<button
										className="modal-close is-large"
										aria-label="close"
										onClick={() => setStatus('')}
										onKeyPress={() => setStatus('')}
									/>
								</div>
							</form>
						)}
					/>
				</div>
			</div>
		)
	}
}

ContactForm.propTypes = {
	title: PropTypes.string,
}

export default ContactForm
