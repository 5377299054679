import React from 'react'
import Helmet from 'react-helmet'
import queryString from 'query-string'

import PageBannerText from '../components/PageBannerText'
import ContactForm from '../components/ContactForm'
import Layout from '../components/layout/old'

const BookConsultation = (props) => {
	const params = queryString.parse(props.location.search)
	return (
		<Layout style={{ paddingBottom: '1rem' }}>
			<Helmet>
				<title>Book a Consultation for Premium Pool Solutions | MineralSwim</title>
				<meta
					name="description"
					content="Our knowledgable sales team are here to help you make best use of your Mineral Swim."
				/>
				<meta name="keywords" content="book, contact, consultation" />
				<link
					rel="canonical"
					href="https://mineralswim.com/book-a-consultation/"
				/>
			</Helmet>
			<PageBannerText
				title={`Book a Consultation`}
				subtitle={`Please submit the form below to enable our Customer Service team to contact you. They'll ask key questions about your current pool system and set-up a consultation with your nearest Mineral Swim Dealer.`}
				params={params}
			/>
			<div style={{ marginBottom: '1rem' }} />
			<ContactForm title={''} />
		</Layout>
	)
}

export default BookConsultation
